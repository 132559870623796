import styles from "./css/Videoplayer.module.css";
import UserHeader from "../CommonComponents/UserHeader";
// import TwitterIcon from "../../assets/twitter-icon.svg";
import CopyLink from "../../assets/copy-link-icon.svg";
// import Vimeo from "@u-wave/react-vimeo";
import ReactPlayer from "react-player";
import Footer from "../CommonComponents/Footer";
import { useVideoPlayerContext } from "../../contexts/VideoPlayerContext";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

export default function Videoplayer(props) {
  const { apiMock, videosFromApi, suggestedVideos, tab, setTab } =
    useVideoPlayerContext();

  let { firstDocTitle, secondDocTitle } = useVideoPlayerContext();

  let { videoId } = useParams();

  const cur = apiMock.videos[tab === firstDocTitle ? 0 : 1].content.find(
    (video) => {
      if (videoId && video.slug !== "") {
        return video.slug === videoId.split(":")[1];
      }
      return true;
    }
  );

  const [currentVideo, setCurrentVideo] = useState(
    !cur ? apiMock.videos[tab === firstDocTitle ? 0 : 1].content[0] : cur
  );
  const [copyLinkText, setCopyLinkText] = useState("Copiar link");

  useEffect(() => {
    if (videoId) {
      setTab(videoId.split(":")[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className={styles.wrapper}>
      <div className={styles.wrapperInner}>
        <div className={styles.header}>
          <UserHeader></UserHeader>
        </div>
        <div className={styles.main}>
          <div className={styles.videoPlayerArea}>
            <div className={styles.videoPlayer}>
              <ReactPlayer
                playing={true}
                controls={true}
                stopOnUnmount={true}
                width="100%"
                height="100%"
                url={currentVideo.vimeo}
              />
            </div>
            <div className={styles.tag}>
              <p>Você está assistindo a</p>
            </div>
            <div className={styles.title}>
              <h1>{currentVideo.title}</h1>
            </div>
            <div className={styles.videoActions}>
              {/* <div className={styles.twitterButton}>
                <button>
                  <span>
                    <img alt="icon" src={TwitterIcon} />
                  </span>
                  <span>Tweetar</span>
                </button>
              </div> */}

              <div className={styles.copyLink}>
                <button
                  onClick={() => {
                    navigator.clipboard.writeText(window.location.href);
                    setCopyLinkText("Copiado!");
                    setTimeout(() => {
                      setCopyLinkText("Copiar link");
                    }, 500);
                  }}
                >
                  <span>
                    <img alt="icon" src={CopyLink} />
                  </span>
                  <span>{copyLinkText}</span>
                </button>
              </div>
            </div>
          </div>

          <div className={styles.rightThumbnailsCont}>
            <img
              className={styles.sectionThumb}
              src={apiMock.videos[tab === firstDocTitle ? 0 : 1].sectionThumb}
              alt={tab}
            />
            <div className={styles.tabs}>
              <div
                className={`${styles.singleTab} ${
                  tab === firstDocTitle ? styles.selectedTab : ""
                }`}
                onClick={() => setTab(firstDocTitle)}
              >
                <span>{firstDocTitle}</span>
              </div>
              <div
                className={`${styles.singleTab} ${
                  tab === secondDocTitle ? styles.selectedTab : ""
                }`}
                onClick={() => setTab(secondDocTitle)}
              >
                <span>{secondDocTitle}</span>
              </div>
            </div>
            <div className={styles.listOfVideoThumbnails}>
              {suggestedVideos.length > 0
                ? suggestedVideos.map((thumbnail, index) => (
                    <VideoThumbnailCard
                      key={index}
                      counter={index}
                      {...thumbnail}
                      tab={tab}
                      videos={suggestedVideos}
                      setCurrentVideo={setCurrentVideo}
                      isCurrentVideo={currentVideo.title === thumbnail.title}
                      isDisabled={!thumbnail.isReleased}
                    ></VideoThumbnailCard>
                  ))
                : null}
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </main>
  );
}

const VideoThumbnailCard = ({
  tab,
  videos,
  title,
  date,
  slug,
  src,
  isCurrentVideo,
  setCurrentVideo,
  isDisabled,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/" + (tab + ":" + slug));

    const cur = videos.find((video) => video.title === title);
    setCurrentVideo(cur);
  };

  return (
    <div
      onClick={handleClick}
      className={`${styles.normalCard} ${
        isDisabled ? styles.cardDisabled : null
      } ${isCurrentVideo ? styles.expandedCard : null}`}
    >
      <div
        className={styles.thumbnail}
        style={{ backgroundImage: `url(${src})` }}
      ></div>
      <div className={styles.titleAndDate}>
        <div className={styles.title}>
          <h3>
            <Link to="">{title}</Link>
          </h3>
        </div>
        <div className={styles.date}>
          <p>{date}</p>
        </div>
      </div>
    </div>
  );
};
