import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Videoplayer from "../components/Videoplayer";
const Routers = () => {
  return (
    <Router>
      <Routes>
        {/* <Route exact path="/" element={<HomePage />} />
        <Route path="/home" element={<HomePage />} /> */}
        <Route path="/" element={<Videoplayer />} />
        <Route path="/:videoId" element={<Videoplayer />} />
        <Route path="/videoplayer/:videoId" element={<Videoplayer />} />
      </Routes>
    </Router>
  );
};

export default Routers;
