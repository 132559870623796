import React from "react";
import styles from "./css/Footer.module.css";
import Logo from "../../../assets/logo/logo.svg";
import Facebook from "../../../assets/social-icons/facebook.svg";
import Instagram from "../../../assets/social-icons/instagram.svg";
import Twitter from "../../../assets/social-icons/twitter.svg";
import Youtube from "../../../assets/social-icons/youtube.svg";
import ArrowUp from "../../../assets/arrow-up.svg";

import { Link } from "react-router-dom";

import MediaQuery from "react-responsive";

export default function Footer(props) {
  return (
    <footer className={styles.wrapper}>
      <div className={styles.s1}>
        <div className={styles.logoCont}>
          <img src={Logo} alt="logo"/>
        </div>
      </div>

      <MediaQuery maxWidth={840}>
        <div className={styles.s24}>
          <div className={styles.navlinksCont}>
            <div className={styles.navlinksH}>
              <span>Redes sociais</span>
            </div>
            <div className={styles.navlinks}>
              <ul>
                <li>
                  Siga a Pato.Academy nas redes sociais para ser o primeiro a
                  receber as novidades.
                </li>
              </ul>
              <div className={styles.socialIcons}>
                <ul>
                  <li>
                    <img alt="icon" src={Facebook} />
                  </li>
                  <li>
                    <img alt="icon" src={Youtube} />
                  </li>
                  <li>
                    <img alt="icon" src={Instagram} />
                  </li>
                  <li>
                    <img alt="icon" src={Twitter} />
                  </li>
                </ul>
              </div>
              <div className={styles.arrowUp}>
                <div className={styles.txt}>
                  <span>De volta ao topo</span>
                </div>
                <div className={styles.arrowIcon} style={{position: 'absolute', right: '40px', top: '40px'}}>
                  <img alt="icon" src={ArrowUp} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </MediaQuery>

      <div className={styles.s2}>
        <div className={styles.s21}>
          <div className={styles.navlinksCont}>
            <div className={styles.navlinksH}>
              <span>Sobre</span>
            </div>
            <div className={styles.navlinks}>
              <ul>
                <li>
                  <Link to="">Pato.Academy</Link>
                </li>
                <li>
                  <Link to="">Gabriel Pato</Link>
                </li>
                <li>
                  <Link to="">“Do Bug ao Bounty”</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.s22}>
          <div className={styles.navlinksCont}>
            <div className={styles.navlinksH}>
              <span>Sobre</span>
            </div>
            <div className={styles.navlinks}>
              <ul>
                <li>
                  <Link to="">Pato.Academy</Link>
                </li>

                <li>
                  <Link to="">Gabriel Pato</Link>
                </li>

                <li>
                  <Link to="">“Do Bug ao Bounty”</Link>
                </li>

                <li>
                  <Link to="">Sit amet</Link>
                </li>

                <li>
                  <Link to="">Consectetur adipiscing</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.s23}>
          <div className={styles.navlinksCont}>
            <div className={styles.navlinksH}>
              <span>Sobre</span>
            </div>
            <div className={styles.navlinks}>
              <ul>
                <li>
                  <Link to="">Pato.Academy</Link>
                </li>

                <li>
                  <Link to="">Gabriel Pato</Link>
                </li>

                <li>
                  <Link to="">“Do Bug ao Bounty”</Link>
                </li>

                <li>
                  <Link to="">In voluptate velit esse</Link>
                </li>

                <li>
                  <Link to="">Duis aute irure</Link>
                </li>

                <li>
                  <Link to="">Cillum dolore eu fugiat</Link>
                </li>

                <li>
                  <Link to="">Mollit anim</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <MediaQuery minWidth={841}>
          <div className={styles.s24}>
            <div className={styles.navlinksCont}>
              <div className={styles.navlinksH}>
                <span>Redes sociais</span>
              </div>
              <div className={styles.navlinks}>
                <ul>
                  <li>
                    Siga a Pato.Academy nas redes sociais para ser o primeiro a
                    receber as novidades.
                  </li>
                </ul>
                <div className={styles.socialIcons}>
                  <ul>
                    <li>
                      <img alt="icon" src={Facebook} />
                    </li>
                    <li>
                      <img alt="icon" src={Youtube} />
                    </li>
                    <li>
                      <img alt="icon" src={Instagram} />
                    </li>
                    <li>
                      <img alt="icon" src={Twitter} />
                    </li>
                  </ul>
                </div>
                <div className={styles.arrowUp}>
                  <div className={styles.txt}>
                    <span>De volta ao topo</span>
                  </div>
                  <div className={styles.arrowIcon}>
                    <img alt="icon" src={ArrowUp} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </MediaQuery>
      </div>
      <div className={styles.s3}>
        <div className={styles.copyRight}>
          <span>© 2022 Pato.Academy.</span>
        </div>
        <div className={styles.navlinks}>
          <ul>
            <li>
              <Link to="">Termos de Uso</Link>
            </li>
            <li>
              <Link to="">Política de Privacidade</Link>
            </li>
            <li>
              <Link to="">Legal</Link>
            </li>
            <li>
              <Link to="">Mapa do Site</Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}
