import React from "react";
import Logo from "../../../assets/logo/logo.svg";
import MobileLogo from "../../../assets/logo/mobile-logo.svg";
import styles from "./css/UserHeader.module.css";
import MediaQuery from "react-responsive";

import { Link } from "react-router-dom";

export default function UserHeader(props) {
  return (
    <header className={styles.wrapper}>
      <div className={styles.left}>
        <Link className={styles.logoCont} to="/">
          <MediaQuery maxWidth={600}>
            <img src={MobileLogo} alt="logo" />
          </MediaQuery>

          <MediaQuery minWidth={601}>
            <img src={Logo} alt="logo" />
          </MediaQuery>
        </Link>
      </div>
    </header>
  );
}
