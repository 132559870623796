import React from "react";
import ReactDOM from "react-dom";
import { VideoPlayerProvider } from "./contexts/VideoPlayerContext";
import './index.css';
import Routers from './Router';


ReactDOM.render(
  (
  <VideoPlayerProvider>
    <Routers></Routers>
  </VideoPlayerProvider>),
  document.getElementById("root")
);
