import { createContext, useState, useEffect, useContext } from "react";
import apiMock from "../mock.json";

export const VideoPlayerContext = createContext({});

export function VideoPlayerProvider({ children }) {
  const firstDocTitle = apiMock.videos[0].sectionName;
  const secondDocTitle = apiMock.videos[1].sectionName;

  const [suggestedVideos, setSuggestedVideos] = useState([]);
  const [tab, setTab] = useState(firstDocTitle);
  useEffect(() => {
    setSuggestedVideos(apiMock.videos[tab === firstDocTitle ? 0 : 1].content);
  }, [tab]);

  return (
    <VideoPlayerContext.Provider
      value={{
        suggestedVideos,
        setSuggestedVideos,
        tab,
        setTab,
        apiMock,
        firstDocTitle,
        secondDocTitle,
      }}
    >
      {children}
    </VideoPlayerContext.Provider>
  );
}

export function useVideoPlayerContext() {
  return useContext(VideoPlayerContext);
}
